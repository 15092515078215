import React from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const Layout = ({ children }) => {
  return (
    <div className="adminLayout">
      <div className="upper">
        <Navbar />
      </div>
      <div className="below">
        <div className="left">
          <Sidebar />
        </div>
        <div className="right">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
