import React, { useRef } from "react";
import calender_icons from "../../../assets/images/Calendar month.svg";
import search_icons from "../../../assets/images/searchIcons.svg";
import { useEmployee } from "../../../context/EmpProvider";

const Heading = ({ tab, setTab }) => {
  const datePickerRef = useRef(null);
  const {
    attendanceData,
    setAttendanceData,
    attendanceFilter,
    setAttendanceFilter,
    filteredData,
    setFilteredData,
    completeLog,
    setCompleteLog,
  } = useEmployee();
  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.showPicker(); // Triggers the native date picker
    }
  };
  const searchEmployees = (data) => {
    if (tab === 0) {
      const attendanceInfo = attendanceFilter.filter((employee) => {
        return (
          employee.employeeId.toLowerCase().includes(data.toLowerCase()) ||
          employee.employeeName.toLowerCase().includes(data.toLowerCase())
        );
      });
      setAttendanceData(attendanceInfo);
      // console.log(attendanceInfo);
    } else {
      const attendanceInfo = completeLog.filter((employee) => {
        return (
          employee.employeeId.toLowerCase().includes(data.toLowerCase()) ||
          employee.employeeName.toLowerCase().includes(data.toLowerCase())
        );
      });
      setFilteredData(attendanceInfo);
      // console.log(attendanceInfo);
    }
  };
  return (
    <div className="heading_Att_reports">
      <div className="left_side">
        <div className="one">
          <h3>For Today .</h3>
          <p>&nbsp; {attendanceFilter.length} records found</p>
        </div>
        <div id="two">
          {["Attendance Reports", "Complete Log"].map((t, i) => (
            <button
              key={i}
              id={`${tab === i || 0 ? "activeTab" : ""}`}
              onClick={() => setTab(i)}
            >
              <span>{t}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="right_side">
        {/* <div className="calender">
          <img
            src={calender_icons}
            alt="calendar_icons"
            onClick={handleIconClick}
            style={{ cursor: "pointer" }}
          />
          <input
            type="date"
            ref={datePickerRef}
            style={{
              position: "absolute",
              visibility: "hidden",
            }}
            onChange={(e) => console.log(e.target.value)} // Handle the selected date here
          />
        </div> */}
        <div className="searchBox">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => searchEmployees(e.target.value)}
            style={{ "--search-icon": `url(${search_icons})` }}
          />
        </div>
      </div>
    </div>
  );
};

export default Heading;
