import { useCustomer } from "../../context/CustomerProvider";

// export default RedirectPage;
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../assets/gif/Loader.gif"; // Import your loader gif

const RedirectPage = () => {
  const { setIsAuthenticated, isUpdate, setIsUpdate } = useCustomer();

  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/verifyCustomerId`,
          { customerid: token }
        );
        if (response.data.exists) {
          localStorage.setItem("CustomerID", token);

          setIsAuthenticated(true);
          setIsUpdate(!isUpdate);
          navigate("/emp-details");
        } else {
          // If the customer ID does not exist, log them out and redirect

          setError("Customer ID not found. Please log in.");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Redirect to login on error
        navigate("/login");
      } finally {
        setLoading(false); // Stop loading after the API call
      }
    };

    if (token) {
      verifyToken();
    } else {
      // Redirect to login if no token
      navigate("/login");
      setLoading(false); // Stop loading if there's no token
    }
  }, [token, navigate]);

  // Show loader while data is being fetched
  if (loading) {
    return (
      <div className="Loader">
        <img src={Loader} alt="Loading" />
      </div>
    );
  }

  return null; // Optionally return null when not loading
};

export default RedirectPage;
