import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Hls from "hls.js";

import axios from "axios";
import Box from "@mui/material/Box";
import Dropdown from "../../assets/images/dropdown.svg";
import Capture from "../../assets/images/Capture.svg";
import Upload from "../../assets/images/Upload.svg";
import { useEmployee } from "../../context/EmpProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
  height: "70%",
  width: "60%",
  borderRadius: "20px",
  backgroundColor: "#fff",
};

const BaseURL = process.env.REACT_APP_BASE_URL;
const EditProfile = ({
  open,
  handleClose: modelClose,
  setEmployeeDetails,
  employeeDetails,
}) => {
  const { isUpdate, setIsUpdate, employee, setEmployee } = useEmployee();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [employeeidformats, setEmployeeIDFormat] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [locations, setLocations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employmenttypes, setEmploymentTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isRegisterEnabled, setRegisterEnabled] = useState(false);
  const [showdetail, setShowDetail] = useState(false);
  const [showvideo, setShowVideo] = useState(false);
  const [camdetails, setCamdetails] = useState({});
  const [showdone, setShowDone] = useState(false);
  const [showtraining, setShowTraining] = useState(false);
  const [buttontext, setButtonText] = useState("Check for Preview");
  const [filteredDepartmentIds, setFilteredDepartmentIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [faceregistered, setFaceRegistered] = useState(false);
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [empAddress, setEmployeeAddress] = useState({
    country: "",
    city: "",
    states: "",
    pincode: "",
    address: "Bhagalpur,Bihar,812005,Bihar",
  });

  console.log(empAddress);

  const [formData, setFormData] = useState({
    customerid: localStorage.getItem("CustomerID"),
    employeeId: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    emailPersonal: "",
    emailOfficial: "",
    mobileNo: "",
    country: selectedCountry,
    state: selectedState,
    city: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    joiningDate: "",
    workLocation: "",
    department: "",
    designation: "",
    employmentType: "",
    blockOrBuildingNo: "",
    shift: "",
    idProofType: "",
    idProofNo: "",
    idProofPhoto: {
      name: "",
      data: "",
    },
    profilePhoto: null,
  });
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    setFormData({
      customerid: localStorage.getItem("CustomerID"),
      employeeId: employeeDetails?.employeeId,
      firstName: employeeDetails?.firstName,
      lastName: employeeDetails?.lastName,
      dob: formatDate(employeeDetails?.dob),
      gender: employeeDetails?.gender,
      emailPersonal: employeeDetails?.emailPersonal,
      emailOfficial: employeeDetails?.emailOfficial,
      mobileNo: employeeDetails?.mobileNo,
      country: employeeDetails?.country,
      state: employeeDetails?.state,
      city: employeeDetails?.city,
      addressLine1: employeeDetails?.addressLine1,
      addressLine2: employeeDetails?.addressLine2,
      pincode: employeeDetails?.pincode,
      joiningDate: formatDate(employeeDetails?.joiningDate),
      workLocation: employeeDetails?.workLocation,
      department: employeeDetails?.department,
      designation: employeeDetails?.designation,
      employmentType: employeeDetails?.employmentType,
      blockOrBuildingNo: employeeDetails?.blockOrBuildingNo,
      shift: employeeDetails?.shift,
      idProofType: employeeDetails?.idProofType,
      idProofNo: employeeDetails?.idProofNo,
      idProofPhoto: {
        name: employeeDetails?.idProofPhoto?.name,
        data: employeeDetails?.idProofPhoto?.data,
      },
      profilePhoto: employeeDetails?.profilePhoto,
    });
  }, [employeeDetails]);

  console.log(formData);
  console.log(empAddress);
  const [inputErrors, setInputErrors] = useState({
    employeeIdPart1: false,
    employeeIdPart2: false,
    employeeIdPart3: false,
    firstName: false,
    lastName: false,
    dob: false,
    gender: false,
    emailPersonal: false,
    emailOfficial: false,
    mobileNo: false,
    country: false,
    state: false,
    city: false,
    addressLine1: false,
    addressLine2: false,
    pincode: false,
    joiningDate: false,
    workLocation: false,
    department: false,
    designation: false,
    employmentType: false,
    blockOrBuildingNo: false,
    shift: false,
    idProofType: false,
    idProofNo: false,
    idProofPhoto: false,
    camera: false,
    profilePhoto: false,
  });

  console.log(inputErrors,"sdsdsds");
  

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;

    if (value.trim() === "") {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg",
      ];

      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setFormData({
            ...formData,
            idProofPhoto: {
              name: file.name,
              data: reader.result,
            },
          });
        };

        reader.readAsDataURL(file);
        setSelectedFileName(file.name);
      } else {
        e.target.value = null;
      }
    }
  };

  const handleSubmit = async () => {
console.log(formData)

if(!formData.idProofNo){
  setInputErrors((prev)=>({
    ...prev,idProofNo:true
  }))
  return;
}

if(!formData.idProofPhoto){
  setInputErrors((prev)=>({
    ...prev,idProofPhoto:true
  }))
  return;
}

if (!formData.pincode || 
  isNaN(formData.pincode) || 
  formData.pincode.length !== 6 || 
  Number(formData.pincode) <= 0) {

setInputErrors((prev) => ({
  ...prev,
  pincode: true
}));
return;
}
if(!formData.dob){
  setInputErrors((prev)=>({
    ...prev,dob:true
  }))
  return;
}

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
if (!formData.emailPersonal || formData.emailPersonal.length === 0 || !EMAIL_REGEX.test(formData.emailPersonal)) {
  setInputErrors((prev) => ({
    ...prev,
    emailPersonal: true
  }));
  return;
}

if (!formData.emailOfficial || formData.emailOfficial.length === 0 || !EMAIL_REGEX.test(formData.emailOfficial)) {
  setInputErrors((prev) => ({
    ...prev,
    emailOfficial: true
  }));
  return;
}





if (!formData.mobileNo || 
  isNaN(formData.mobileNo) || 
  formData.mobileNo.length !== 10 || 
  Number(formData.mobileNo) <= 0) {

setInputErrors((prev) => ({
  ...prev,
  mobileNo: true
}));
return;
}
// Function to validate address fields
const validateAddressFields = (address) => {
  // Constants
  const MAX_ADDRESS_LENGTH = 100; // Example length limit
  const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.#-]+$/; // Allowed characters

  // Trim addresses
  address = address.trim();


  // Check if address1 is provided
  if (!address) {
    return "Address1 is required";
  }

  // Check if address1 exceeds maximum length
  if (address.length > MAX_ADDRESS_LENGTH) {
    return "Address1 exceeds maximum length";
  }

  // Check if address1 contains invalid characters
  if (!ADDRESS_REGEX.test(address)) {
    return "Address1 contains invalid characters";
  }

  // If all checks pass
  return null;
};

// Example usage
const addressError1 = validateAddressFields(formData.addressLine1);
// if (addressError) {
//   setInputErrors((prev) => ({
//     ...prev,
//     address: addressError
//   }));
//   return;
// }

if(addressError1){
  setInputErrors((prev)=>({
    ...prev,addressLine1:true
  }))
  return;
}
const addressError2 = validateAddressFields(formData.addressLine2);
if(addressError2){
  setInputErrors((prev)=>({
    ...prev,addressLine2:true
  }))
  return;
}
// if(!formData.joiningDate){
//   setInputErrors((prev)=>({
//     ...prev,joiningDate:true
//   }))
//   return;
// }


if(!formData.country){
  setInputErrors((prev)=>({
    ...prev,country:true
  }))
  return;
}

if(!formData.state){
  setInputErrors((prev)=>({
    ...prev,state:true
  }))
  return;
}

if(!formData.city){
  setInputErrors((prev)=>({
    ...prev,city:true
  }))
  return;
}
    const info = {
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      dob: formData.dob || "",
      gender: formData.gender || "",
      emailPersonal: formData.emailPersonal || "",
      emailOfficial: formData.emailOfficial || "",
      mobileNo: formData.mobileNo || "",
      address: `${formData.addressLine1 || ""}, ${
        formData.addressLine2 || ""
      }, ${formData.city || ""}, ${formData.state || ""}, ${
        formData.country || ""
      } - ${formData.pincode || ""}`,
      dateOfJoining: formData.joiningDate || "",
      department: formData.department || "",
      designation: formData.designation || "",
      employmentType: formData.employmentType || "",
      workLocation: formData.workLocation || "",
      blockOrBuildingNo: formData.blockOrBuildingNo || "",
      shift: formData.shift || "",
      idProofType: formData.idProofType || "",
      idProofNo: formData.idProofNo || "",
      country: formData.country || "",
      state: formData.state || "",
      city: formData.city || "",
      pincode: formData.pincode || "",
      idProofPhoto: {
        name: formData.idProofPhoto?.name || "",
        data: formData.idProofPhoto?.data || "",
      },
      addressLine1: formData?.addressLine1,
      addressLine2: formData?.addressLine2,
    };
    console.log(formData);
    console.log(formData?.city);

console.log(info)
    try {
      setLoading(true);
      const response = await axios.put(`${BaseURL}/employees/edit`, info, {
        params: { employeeId: employeeDetails?.employeeId },
      });
      console.log(response.data?.data);
      // console.log(formData);
      if (response?.data?.success) {
        setLoading(false);

        const info = employee?.map((e) => {
          if (e?._id === response?.data?.data?._id) {
            if (response?.data?.data) {
              // localStorage.setItem(
              //   "EmployeeDetails",
              //   JSON.stringify(response?.data?.data)
              // );
              setEmployeeDetails(response?.data?.data); // Set the details of the first employee
            }

            return response?.data?.data;
          }
          return e;
        });
        setEmployee(info);
        setSelectedFileName("");
        // setIsUpdate(!isUpdate);
        modelClose();
        setFormData({
          customerid: localStorage.getItem("CustomerID"),
          employeeIdPart1: "",
          employeeIdPart2: "",
          employeeIdPart3: "",
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          emailPersonal: "",
          emailOfficial: "",
          mobileNo: "",
          country: selectedCountry,
          state: selectedState,
          city: "",
          addressLine1: "",
          addressLine2: "",
          pincode: "",
          joiningDate: "",
          workLocation: "",
          department: "",
          designation: "",
          employmentType: "",
          blockOrBuildingNo: "",
          shift: "",
          idProofType: "",
          idProofNo: "",
          idProofPhoto: {
            name: "",
            data: "",
          },
        });
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const initializeFormData = () => {
    if (employeeidformats?.[0]?.employeeId.length > 0) {
      const firstCompany = employeeidformats[0].employeeId[0];
      setFilteredDepartmentIds(
        employeeidformats[0].employeeId.filter(
          (format) => format.companyid === firstCompany.companyid
        )
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeIdPart1: firstCompany.companyid,
        employeeIdPart2: firstCompany.departmentid,
        employeeIdPart3: firstCompany.employeeid,
      }));
    }
  };

  useEffect(() => {
    initializeFormData();
  }, [employeeidformats]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    const fetchStream = async () => {
      try {
        const response = await axios.post(
          "http://localhost:10000/camera/getrtsp",
          {
            rtsplink: camdetails.rtsplink,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { streamUrl } = response.data;

        if (Hls.isSupported()) {
          hlsRef.current = new Hls();
          hlsRef.current.loadSource(`http://localhost:10000${streamUrl}`);
          hlsRef.current.attachMedia(videoRef.current);

          hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.error("Network error: retrying...");
                  hlsRef.current.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.error("Media error: trying to recover...");
                  hlsRef.current.recoverMediaError();
                  break;
                default:
                  hlsRef.current.destroy();
                  console.error("HLS fatal error, destroying instance...");
                  break;
              }
            }
          });
        } else if (
          videoRef.current.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = `http://localhost:10000${streamUrl}`;
        }
      } catch (error) {
        console.error("Error fetching the stream:", error);
      }
    };
    if (showvideo) {
      fetchStream();
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [showvideo, BaseURL]);

  useEffect(() => {
    const fetchLocations = `${BaseURL}/masters/location/get`;
    const fetchCameras = `${BaseURL}/camera/getcam`;
    const fetchShifts = `${BaseURL}/masters/shiftname/get`;
    const fetchBuildings = `${BaseURL}/masters/building/get`;
    const fetchDepartments = `${BaseURL}/masters/department/get`;
    const fetchDesignations = `${BaseURL}/masters/designation/get`;
    const fetchEmploymentType = `${BaseURL}/masters/employmenttype/get`;
    const fetchEmploymentIDFormat = `${BaseURL}/masters/employeeidformat/get`;

    const customerId = localStorage.getItem("CustomerID");

    const fetchData = async () => {
      try {
        const [
          locationRes,
          cameraRes,
          shiftRes,
          buildingRes,
          departmentRes,
          designationRes,
          employmenttypeRes,
          employmentidformatRes,
        ] = await Promise.all([
          axios.post(fetchLocations, { customerid: customerId }),
          axios.post(fetchCameras, { customerid: customerId }),
          axios.post(fetchShifts, { customerid: customerId }),
          axios.post(fetchBuildings, { customerid: customerId }),
          axios.post(fetchDepartments, { customerid: customerId }),
          axios.post(fetchDesignations, { customerid: customerId }),
          axios.post(fetchEmploymentType, { customerid: customerId }),
          axios.post(fetchEmploymentIDFormat, { customerid: customerId }),
        ]);
        setLocations(locationRes.data.locations);
        setCameras(cameraRes.data.cameras);
        setShifts(shiftRes.data.shiftnames);
        setBuildings(buildingRes.data.buildings);
        setDepartments(departmentRes.data.departments);
        setDesignations(designationRes.data.designations);
        setEmploymentTypes(employmenttypeRes.data.employmenttypes);
        setEmployeeIDFormat(employmentidformatRes.data.employmentidformats);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (employeeidformats?.[0]?.employeeId.length > 0) {
      const firstCompany = employeeidformats[0].employeeId[0];
      setFilteredDepartmentIds(
        employeeidformats[0].employeeId.filter(
          (format) => format.companyid === firstCompany.companyid
        )
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeIdPart1: firstCompany.companyid,
        employeeIdPart2: firstCompany.departmentid,
        employeeIdPart3: firstCompany.employeeid,
      }));
    }
  }, [employeeidformats]);

  useEffect(() => {
    if (formData.employeeIdPart1) {
      const selectedCompanyFormats = employeeidformats?.[0]?.employeeId.filter(
        (format) => format.companyid === formData.employeeIdPart1
      );

      const departmentDetails = selectedCompanyFormats;

      setFilteredDepartmentIds(departmentDetails);

      const initialCompany = departmentDetails.find(
        (format) => format.departmentid === formData.employeeIdPart2
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeIdPart2: initialCompany?.departmentid || "",
        employeeIdPart3: initialCompany?.employeeid || "",
      }));
    }
  }, [formData.employeeIdPart1, formData.employeeIdPart2, employeeidformats]);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryList = response.data
          .map((country) => ({
            name: country.name.common,
            code: country.cca2,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setCountries(countryList);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, [employeeDetails]);

  useEffect(() => {
    console.log(selectedCountry);
    if (selectedCountry) {
      axios
        .get(`http://api.geonames.org/searchJSON`, {
          params: {
            country: selectedCountry,
            featureCode: "ADM1",
            maxRows: 100,
            username: "sample_space",
          },
        })
        .then((response) => {
          const stateList = response.data.geonames
            .map((state) => ({
              name: state.name,
              code: state.adminCode1,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          console.log(stateList);
          setStates(stateList);
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
        });
    }
  }, [selectedCountry, employeeDetails]);
  console.log(states);
  useEffect(() => {
    if (selectedState) {
      axios
        .get(`http://api.geonames.org/searchJSON`, {
          params: {
            adminCode1: selectedState,
            country: selectedCountry,
            featureClass: "P",
            maxRows: 1000,
            username: "sample_space",
          },
        })
        .then((response) => {
          const cityList = response.data.geonames
            .map((city) => ({
              name: city.name,
              code: city.geonameId,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));

          setCities(cityList);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }, [selectedState, employeeDetails]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };


  const handlePincode = async (e) => {
    const { name, value } = e.target;


    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));


    if (name === "pincode" && value.trim() !== "") {
      const apiKey = "3af4f7b81d014bdfb6aaa954ce3a6dfe";


      try {
        const response = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${value}&key=${apiKey}`
        );
        if (!response.ok) throw new Error("Failed to fetch data");
        


        const data = await response.json();
        const { results } = data;
        console.log(results,"gdgdfgdfgdfgd")


        if (results && results.length > 0) {
          const { components } = results[0];
          const { country, state, state_district } = components;
          console.log(state_district,"city");
          setFormData((prevData) => ({
            ...prevData,
            country: country,
            state: state,
            city: state_district,
          }));


          
        } else {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "No data found for the given pincode",
          }));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };


  return (
    <Modal
      open={open}
      onClose={modelClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style} id="editProfile">
        <div className="Main_Section">
          <div className="Employee_Register">
            <h1>
              Edit Employee <span>*</span>
            </h1>
            <div className="Employee_Input_Section">
              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Emp Id</div>
                  <input
                    type="text"
                    name="empId"
                    value={formData.employeeId}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>

                <div className="Input_Field">
                  <div className="Label">First Name</div>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Last Name</div>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
                <div className="Input_Field">
                  <div className="Label">Date of Birth</div>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{ borderColor: inputErrors.dob ? "red" : "#ccc" }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.gender ? "red" : "#ccc",
                      }}
                    >

                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Gender</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Label">Email (Personal)</div>
                  <input
                    type="email"
                    name="personalEmail"
                    value={formData.emailPersonal}
                    onChange={(e) =>
                     {
                      setFormData((prev) => ({
                        ...prev,
                        emailPersonal: e.target.value,
                      }));
                      setInputErrors((prevErrors) => ({
                        ...prevErrors,
                        emailPersonal: false,
                      }));
                     }
                    }
                    // onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.emailPersonal ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Email (Official)</div>
                  <input
                    type="email"
                    name="officialEmail"
                    value={formData.emailOfficial}
                    // onChange={handleInputChange}
                    onChange={(e) =>
                    {
                      setFormData((prev) => ({
                        ...prev,
                        emailOfficial: e.target.value,
                      }))
                      setInputErrors((prevErrors) => ({
                        ...prevErrors,
                        emailOfficial: false,
                      }));
                    }
                    }
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.emailOfficial ? "red" : "#ccc",
                    }}
                  />
                  {/* <h6
                    style={{
                      color: inputErrors.officialEmail ? "red" : "#000",
                    }}
                  >
                    E-mail already registered
                  </h6> */}
                </div>

                <div className="Input_Field">
                  <div className="Label">Mobile No.</div>
                  <input
                    type="number"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.mobileNo ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              {/* <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="country"
                      value={formData.country}
                      onChange={(e) => {
                        const selectedCountry = countries.find(
                          (country) => country.name === e.target.value
                        );

                        setFormData((prevData) => ({
                          ...prevData,
                          country: selectedCountry?.name || "",
                        }));
                        setSelectedCountry(selectedCountry?.code || "");
                        setInputErrors((prevData) => ({
                          ...prevData,
                          country: false,
                        }));
                      }}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.country ? "red" : "#ccc",
                      }}
                    >
                      {countries.map((country, index) => (
                        <option
                          key={index}
                          name={country.name}
                          value={country.name}
                        >
                          {country.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Country</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div
                    className="Custom_Select active"
                    onClick={() => console.log(formData.state)}
                  >
                    <select
                      name="state"
                      value={formData.state} 
                      onChange={(e) => {
                        const selectedState = states.find(
                          (state) => state.name === e.target.value
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          state: selectedState?.name || "",
                        }));
                        setSelectedState(selectedState?.name || "");
                        setInputErrors((prevData) => ({
                          ...prevData,
                          state: false,
                        }));
                      }}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.state ? "red" : "#ccc",
                      }}
                    >
                     
                      {states.map((state, index) => (
                        <option
                          key={index}
                          value={state.name} 
                          name={state.name}
                        >
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">State</div>
                  </div>
                </div>
              </div> */}

              {/* <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="city"
                      value={formData.city}
                      onChange={(e) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          city: e.target.value,
                        }));
                        handleInputChange(e);
                        setInputErrors((prevData) => ({
                          ...prevData,
                          city: false,
                        }));
                      }}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.city ? "red" : "#ccc",
                      }}
                    >

                      {cities.map((city, index) => (
                        <option key={index} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">City</div>
                  </div>
                </div>
              </div> */}

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Address. 1</div>

                  <input
                    type="text"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.addressLine1 ? "red" : "#ccc",
                    }}
                  />
                </div>

                <div className="Input_Field">
                  <div className="Label">Address Line 2</div>
                  <input
                    type="text"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.addressLine2 ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>
              {/* <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Pincode</div>
                  <input
                    type="number"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.pincode ? "red" : "#ccc",
                    }}
                  />
                </div>

                <div className="Input_Field">
                  <div className="Label">Date of Joining</div>
                  <input
                    type="date"
                    name="joiningDate"
                    value={formData.joiningDate}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.joiningDate ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div> */}

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">Pincode</div>
                  <input
                    type="number"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handlePincode}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.pincode ? "red" : "#ccc",
                    }}
                  />
                </div>
                <div className="Input_Field">
                  <div className="Label">country</div>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.country ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>


              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Label">state</div>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.state ? "red" : "#ccc",
                    }}
                  />
                </div>
                <div className="Input_Field">
                  <div className="Label">city</div>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.city ? "red" : "#ccc",
                    }}
                  />
                </div>
                <div className="Input_Field">
                  <div className="Label">Date of Joining</div>
                  <input
                    type="date"
                    name="joiningDate"
                    value={formData.joiningDate}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.joiningDate ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>
              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="workLocation"
                      value={formData.workLocation}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.workLocation ? "red" : "#ccc",
                      }}
                    >

                      {locations !== undefined &&
                        locations.map((location) => (
                          <option
                            key={location._id}
                            value={`${location.city} - ${location.pincode}`}
                          >
                            {location.city} - {location.pincode}
                          </option>
                        ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Work Location</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="department"
                      value={formData.department}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.department ? "red" : "#ccc",
                      }}
                    >

                      {departments?.map((department, index) => (
                        <option key={index} value={department.department}>
                          {department.department}
                        </option>
                      ))}
                    </select>

                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Department</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="designation"
                      value={formData.designation}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.designation ? "red" : "#ccc",
                      }}
                    >

                      {designations?.map((designation, index) => (
                        <option key={index} value={designation.designation}>
                          {designation.designation}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Designation</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="employmentType"
                      value={formData.employmentType}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.employmentType
                          ? "red"
                          : "#ccc",
                      }}
                    >

                      {employmenttypes?.map((employmenttype, index) => (
                        <option
                          key={index}
                          value={employmenttype.employmenttype}
                        >
                          {employmenttype.employmenttype}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Employment Type</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="blockOrBuildingNo"
                      value={formData.blockOrBuildingNo}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.blockOrBuildingNo
                          ? "red"
                          : "#ccc",
                      }}
                    >

                      {buildings?.map((building, index) => (
                        <option key={index} value={building.buildingno}>
                          {building.buildingno}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Building No.</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="shift"
                      value={formData.shift}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.shift ? "red" : "#ccc",
                      }}
                    >

                      {shifts?.map((shift, index) => (
                        <option
                          key={index}
                          value={`${shift.fromtiming} - ${shift.totiming}`}
                        >
                          {shift.fromtiming} - {shift.totiming}
                        </option>
                      ))}
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">Shift</div>
                  </div>
                </div>
              </div>

              <div className="Employee_Inputs">
                <div className="Input_Field">
                  <div className="Custom_Select active">
                    <select
                      name="idProofType"
                      value={formData.idProofType}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      style={{
                        borderColor: inputErrors.idProofType ? "red" : "#ccc",
                      }}
                    >

                      <option value="Aadhar Card">Aadhar Card</option>
                      <option value="Driving License">Driving License</option>
                      <option value="PAN Card">PAN Card</option>
                    </select>
                    <img
                      src={Dropdown}
                      alt="Dropdown arrow"
                      className="Dropdown_Arrow"
                    />
                    <div className="Label">ID Proof</div>
                  </div>
                </div>

                <div className="Input_Field">
                  <div className="Label">ID Proof No.</div>
                  <input
                    type="text"
                    name="idProofNo"
                    value={formData.idProofNo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    style={{
                      borderColor: inputErrors.idProofNo ? "red" : "#ccc",
                    }}
                  />
                </div>
              </div>

              <div className="Employee_Inputs">
                <div
                  className="Input_Field file "
                  id="uploadId"
                  style={{
                    borderColor: inputErrors.idProofPhoto ? "red" : "#ccc",
                  }}
                >
                  <div className="Label">ID Proof Photo</div>
                  <input
                    type="file"
                    name="idProofPhoto"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    onBlur={handleInputBlur}
                  />
                  {selectedFileName && (
                    <div className="FileNameLabel">{selectedFileName}</div>
                  )}
                  {!selectedFileName && (
                    <div className="FileNameLabel">
                      {formData?.idProofPhoto?.name}
                    </div>
                  )}

                  <img
                    className="Upload"
                    src={Upload}
                    alt="Click to upload"
                    style={{ cursor: "pointer" }}
                    onClick={handleImageClick}
                  />

                  <p className="imgType">
                    Upload only .pdf, .png, .jpg, .jpeg files.
                  </p>
                </div>
              </div>

              <div className="Heading_Submit">
                <button
                  style={{
                    border: isRegisterEnabled
                      ? "1px solid #0a3b66"
                      : "1px solid #dddddd",
                  }}
                  onClick={handleSubmit}
                >
                  {loading ? "Updateing..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditProfile;
