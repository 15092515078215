import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import employeePic from "../../assets/images/employeeDetials.svg";
import searchIcons from "../../assets/images/searchIcons.svg";
import filterIcons from "../../assets/images/Filter list alt.svg";
import employeePicList from "../../assets/images/employee.svg";
import { useEmployee } from "../../context/EmpProvider";
import { useModel } from "../../hooks/useModel/useModel";
import LogDetails from "../../model/logDetails/LogDetails";
import EditProfile from "../../model/editProfile/EditProfile";
import Confirm from "../../model/confirm/Confirm";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import IdProof from "../../model/idProof/IdProof";
import Loader from "../../assets/gif/Loader.gif";
import InfiniteScroll from "react-infinite-scroll-component";
const EmployeeDetails = () => {
  const {
    employee,
    setEmployee,
    setError,
    employeeDetails,
    setEmployeeDetails,
    isUpdate,
    setIsUpdate,
  } = useEmployee();
  const { open, handleOpen, handleClose } = useModel();
  const {
    open: open1,
    handleOpen: handleOpen1,
    handleClose: handleClose1,
  } = useModel();
  const {
    open: open2,
    handleOpen: handleOpen2,
    handleClose: handleClose2,
  } = useModel();

  const {
    open: open3,
    handleOpen: handleOpen3,
    handleClose: handleClose3,
  } = useModel();
  const [type, setType] = useState("terminate");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);

  const messageTerminate = {
    title: `${
      employeeDetails?.isTerminated ? "Terminated" : "Terminate"
    } Employee Account?`,
    content: `${
      employeeDetails?.isTerminated
        ? "This employee's account has already been terminated. No further action is required."
        : "Are you sure you want to permanently terminate this employee's account? This action cannot be undone."
    }`,

    leftBtn: `${employeeDetails?.isTerminated ? "" : "Terminate"}`,
    rightBtn: `${employeeDetails?.isTerminated ? "Ok" : "Cancel"}`,
    type: employeeDetails?.isTerminated ? "terminated" : "terminate",
  };

  const messageSuspend = {
    title: `${
      employeeDetails?.isTerminated
        ? "Terminated"
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    } Employee Account?`,
    content: `${
      employeeDetails?.isTerminated
        ? "This employee's account is already terminated. Suspension is not possible."
        : employeeDetails?.isSuspended
        ? "This employee's account is already suspended. You can reactivate it later if needed."
        : "Are you sure you want to suspend this employee's account? The account can be reactivated later, but the user will not have access in the meantime."
    }`,
    leftBtn: `${
      employeeDetails?.isTerminated
        ? ""
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    }`,
    rightBtn: `${
      employeeDetails?.isTerminated
        ? "Ok"
        : employeeDetails?.isSuspended
        ? "Cancel"
        : "Cancel"
    }`,
    type: `${
      employeeDetails?.isTerminated
        ? "Terminated"
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    }`,
  };

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadMoreData,setLoadMoreData]=useState(false);
  const limit = 5;
  useEffect(() => {
    loadMoreEmployees();
    console.log("isUpdate", isUpdate);
  }, [isUpdate]);
  // useEffect(() => {
  //   const storedEmployeeDetails = localStorage.getItem("EmployeeDetails");

  //   if (storedEmployeeDetails) {
  //     setEmployeeDetails(JSON.parse(storedEmployeeDetails));
  //   }
  // }, []);
  const onEmployeeSelect = (id) => {
    const info = employee.find((e) => e?._id === id);
    if (info) {
      setEmployeeDetails(info);
    }
  };

  const handleTerminate = () => {
    setType("terminate");

    handleOpen2();
  };
  const handleSuspended = () => {
    setType("suspend");
    handleOpen2();
  };
  const loadMoreEmployees = async () => {
    try {
      setLoading(true);
      const customerid = localStorage.getItem("CustomerID");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeListIndividuals-customers`,
        {
          params: { page, limit, isFaceRegistered: true, customerid },
        }
      );

      const { employees: newEmployees, pagination } = response.data.data;

      setEmployee((prevEmployees) => {
        // Create a set of existing employee IDs for quick lookup
        const existingEmployeeIds = new Set(
          prevEmployees.map((employee) => employee._id)
        );

        // Filter out employees that already exist in the current state
        const filteredNewEmployees = newEmployees.filter(
          (newEmployee) => !existingEmployeeIds.has(newEmployee._id)
        );

        // Add only the unique employees
        return [...prevEmployees, ...filteredNewEmployees];
      });


      if (newEmployees.length > 0) {
        // localStorage.setItem(
        //   "EmployeeDetails",
        //   JSON.stringify(newEmployees[0])
        // );
        setEmployeeDetails(newEmployees[0]); // Set the details of the first employee
      }
      setLoading(false);

      setPage(pagination.currentPage + 1);
      setHasMore(pagination.hasNextPage);
    } catch (error) {
      console.log(error);
      setLoading(false);
      // console.error("Error fetching employees:", error);
      setError("Error fetching employees");
    }
  };
  const fetchSearchResults = async (query) => {
    try {
      if (!query || query.length == 0 || !query === "") {
        console.log(employee);
        setSearchFilter(false);
        return;
      }
      const customerid = localStorage.getItem("CustomerID");
      setLoadMoreData(true)
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/searchEmployees?q=${query}&customerId=${customerid}`
      );
      // console.log(data);
      setFilteredEmployees(data?.data);
      setSearchFilter(true);
    } catch (e) {
      console.log(e.response.merssage);
    }
  };

  // Debounced version of the fetch function
  const debouncedFetchResults = debounce((query) => {
    fetchSearchResults(query);
  }, 1000);
  const handleSearchChange = (e) => {
    const query = e.target.value;

    setSearchTerm(query);
    debouncedFetchResults(query);
  };

 
  return (
    <Layout>
      {/* {loading && (
        <>
          <div className="Loader">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )} */}

{/* employee.length <= 0 && (
            <div className="list_of_dataLoading">
              <h1>No Employee Details Found</h1>
            </div>
          )}
          {loading ? (
            <div className="liveTrackerLoder">
              <img src={Loader} alt="Loading" />
            </div> */}


      <div className="employeeDetails">
        <div className="one">
          <h3>All Employees </h3>
        </div>

        <div className="commonBorderForAll employeeDetailsSection">
          {loading ? (
    <div className="liveTrackerLoader " style={{ width:"100%", display:"flex",justifyContent:"center",alignItems:"center"}}>
      <img src={Loader} alt="Loading" height={100} width={100}/>
    </div>
  ) : employee.length === 0 ? (
    <div className="list_of_dataLoading">
      <h1>No Employee Details Found</h1>
    </div>
          ) : (
            <>
              {(filteredEmployees.length > 0 || employee.length > 0) && (
                <div class="DetailsOfEmployeeLeft">
                  <div class="Employees_Whole_Data">
                    <div class="Employees_Data_Heading">
                      <div class="Heading_Panel">
                        <h1>Employee Details</h1>
                      </div>
                      <div class="Heading_Panel_A">
                        {/* <div class="Emp_Action_Button" onClick={handleTerminate}>
          <p>
            <span>
              {employeeDetails?.isTerminated
                ? " Terminated"
                : " Terminate"}
            </span>
          </p>
        </div>
        <div class="Emp_Action_Button" onClick={handleSuspended}>
          <p>
            <strong>
              {employeeDetails?.isSuspended
                ? " Suspended"
                : " Suspend"}
            </strong>
          </p>
        </div> */}
                        <div class="Emp_Action_Button" onClick={handleOpen1}>
                          <p>Edit</p>
                        </div>
                      </div>
                    </div>

                    <div className="containerEmpDetails">
                      <div className="Profile_Data">
                        <div className="profilePic">
                          <img
                            src={employeeDetails?.profilePhoto}
                            alt={`${employeeDetails?.idProofPhoto?.name}'s picture`}
                          />
                          <button className="logDetails" onClick={handleOpen}>
                            <span>Log Details</span>
                          </button>
                        </div>
                      </div>
                      <div className="Profile_Container">
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>First Name</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.firstName}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Last Name</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.lastName}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Employee ID</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.employeeId}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>D.O.B</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>
                              {new Date(
                                employeeDetails?.dob
                              ).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Gender</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.gender}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Email ID (Personal)</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.emailPersonal}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Email ID (Official)</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.emailOfficial}</p>
                          </div>
                        </div>
                        <div className="Profile_Content">
                          <div className="Profile_Content_Heading">
                            <p>Mobile No.</p>
                          </div>
                          <div className="T11">
                            <p>:</p>
                          </div>
                          <div className="Profile_Content_Data">
                            <p>{employeeDetails?.mobileNo}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="Profile_Details">
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Address</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.address}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Date of Joining</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>
                          {new Date(
                            employeeDetails?.joiningDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Department</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.department}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>IT</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.designation}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Employment Type</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.employmentType}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Work Location</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.workLocation}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Block No./Building No.</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.blockOrBuildingNo}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>Shift</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.shift}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>ID Proof</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.idProofType}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>ID Proof No.</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data">
                        <p>{employeeDetails?.idProofNo}</p>
                      </div>
                    </div>
                    <div class="Profile_Content">
                      <div class="Profile_Content_Heading">
                        <p>ID Proof Photo</p>
                      </div>
                      <div class="T11">
                        <p>:</p>
                      </div>
                      <div class="Profile_Content_Data" onClick={handleOpen3}>
                        <p>
                          <a
                            href={employeeDetails?.idProofPhoto?.data || "#"} // Use the data URL or a placeholder if data is not available
                            download={`${
                              employeeDetails?.firstName || "Employee"
                            }_ID.png`} // Default name if firstName is empty
                          >
                            Download ID Proof
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="listOfEmployeeRight">
                <div className="All_Employees_List">
                  {(filteredEmployees.length > 0 || employee.length > 0) && (
                    <div className="EmployeeSearch">
                      <form
                        className="SearchForm"
                        //  onSubmit={handleSearchSubmit}
                      >
                        <div className="Search_Input_Data">
                          <div className="InputData">
                            <input
                              type="text"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Enter Employee Name"
                            />
                          </div>
                          <div className="Input_Data_Button">
                            <button type="submit">
                              <img src={searchIcons} alt="Search" />
                            </button>
                          </div>
                        </div>
                        {/* <div className="Search_Filter_Data">
          <img src={filterIcons} alt="Filter" />
        </div> */}
                      </form>
                    </div>
                  )}

                  <div className="Employees_Lists">
                    {searchFilter &&
                      filteredEmployees.map((data, i) => (
                        <div
                          key={i}
                          className="Employee_List_Data"
                          onClick={() => onEmployeeSelect(data._id)}
                          tabIndex="0"
                          style={{ padding: "10px" }}
                        >
                          <div className="Employee_Picture">
                            <img
                              src={data?.profilePhoto}
                              alt={`${data?.idProofPhoto?.name}'s picture`}
                            />
                          </div>
                          <div className="Employee_Content">
                            <h1 style={{ margin: "0 0 7px 0" }}>
                              {data?.firstName} {data?.lastName}
                            </h1>
                            <p style={{ margin: "0 0 7px 0" }}>
                              <strong style={{ fontWeight: "bold" }}>
                                {data?.designation}
                              </strong>
                            </p>
                            <p style={{ margin: "0 0 7px 0" }}>
                              {data?.emailOfficial}
                            </p>
                            <p style={{ margin: "0" }}>{data?.mobileNo}</p>
                          </div>
                          <div className="status_employee">
                            {data?.isTerminated && (
                              <div className="circle_status1"></div>
                            )}

                            {!data?.isTerminated && data?.isSuspended && (
                              <div className="circle_status2"></div>
                            )}
                          </div>
                        </div>
                      ))}
                    {(filteredEmployees.length > 0 || employee.length > 0) && (
                      <InfiniteScroll
                        dataLength={employee.length}
                        next={loadMoreEmployees}
                        hasMore={hasMore}
                        height={500}
                        loader={
                          <img
                            src={Loader}
                            alt="Loading"
                            style={{
                              width: "50px",
                              height: "50px",
                              display: "block",
                              margin: "0 auto",
                            }}
                          />
                        }
                        className="hide-scrollbar"
                        style={{ overflow: "auto" }}
                      >
                        {!searchFilter &&
                          employee.map((data, i) => (
                            <div
                              key={i}
                              className="Employee_List_Data"
                              onClick={() => onEmployeeSelect(data._id)}
                              tabIndex="0"
                              style={{ padding: "10px" }}
                            >
                              <div className="Employee_Picture">
                                <img
                                  src={data?.profilePhoto}
                                  alt={`${data?.idProofPhoto?.name}'s picture`}
                                />
                              </div>
                              <div className="Employee_Content">
                                <h1 style={{ margin: "0 0 7px 0" }}>
                                  {data?.firstName} {data?.lastName}
                                </h1>
                                <p style={{ margin: "0 0 7px 0" }}>
                                  <strong style={{ fontWeight: "bold" }}>
                                    {data?.designation}
                                  </strong>
                                </p>
                                <p style={{ margin: "0 0 7px 0" }}>
                                  {data.emailOfficial}
                                </p>
                                <p style={{ margin: "0" }}>{data.mobileNo}</p>
                              </div>
                              <div className="status_employee">
                                {data?.isTerminated && (
                                  <div className="circle_status1"></div>
                                )}

                                {!data?.isTerminated && data?.isSuspended && (
                                  <div className="circle_status2"></div>
                                )}
                              </div>
                            </div>
                          ))}
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
                {/* {hasMore && (
    <div className="loadMore_container">
      <button className="loadMoreBtn" onClick={loadMoreEmployees}>
        <span>{loading ? "Loading..." : "Load More"}</span>
      </button>
    </div>
  )} */}
              </div>
            </>
          )}
        </div>
      </div>
      <LogDetails
        open={open}
        handleClose={handleClose}
        employeeId={employeeDetails.employeeId}
        employeeName={employeeDetails.firstName}
      />
      <EditProfile
        open={open1}
        handleClose={handleClose1}
        setEmployeeDetails={setEmployeeDetails}
        employeeDetails={employeeDetails}
      />
      <Confirm
        open={open2}
        handleClose={handleClose2}
        message={type === "terminate" ? messageTerminate : messageSuspend}
        setEmployeeDetails={setEmployeeDetails}
        employeeDetails={employeeDetails}
      />
      <IdProof
        open={open3}
        handleClose={handleClose3}
        idPic={employeeDetails?.idProofPhoto?.data}
      />
    </Layout>
  );
};

export default EmployeeDetails;
