import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./App.css";
import "./styles/colors/color.css";
import "./components/Layout/AdminLayout/layout.css";
import "./components/Layout/Navbar/navbar.css";
import "./components/Layout/Sidebar/sidebar.css";
import "./pages/reports/reportsDetails.css";
import "./pages/adminAccount/adminAcount.css";
import "./pages/liveTracker/liveTracker.css";
import "./pages/exployeeDetails/employeeDetails.css";
import "./pages/Dashboard/dashboard.css";
import "./pages/auth/login/login.css";
import "./model/logDetails/logDetails.css";
import "./model/confirm/confirm.css";
import "./model/editProfile/editProfile.css";
import Loader from "./assets/gif/Loader.gif";
import "./pages/animation/animation.css";
import "./components/reports/reportTable/attendanceTable.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import ReportsDetails from "./pages/reports/ReportsDetails";
import AdminAccount from "./pages/adminAccount/AdminAccount";
import LiveTracker from "./pages/liveTracker/LiveTracker";
import EmployeeDetails from "./pages/exployeeDetails/EmployeeDetails";
import Login from "./pages/auth/login/Login";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import NewPassword from "./pages/auth/newPassword/NewPassword";
import Forgetpassword from "./pages/auth/forgetPassword/Forgetpassword";
import Animation from "./pages/animation/Animation";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useCustomer } from "./context/CustomerProvider";
import RedirectPage from "./pages/redirect/RedirectPage";
// import { io } from "socket.io-client";
// const socket = io("http://localhost:4000");
const PrivateRoute = () => {
  const { customer, isAuthenticated } = useCustomer();

  // Check if customer is authenticated
  return customer && isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

function App() {
  const { setCustomer, customer, setError, setIsAuthenticated, isUpdate } =
    useCustomer();
  // const socket = useMemo(() => io("http://localhost:4000"), []);
  // New loading state for verifying authentication
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("Connected to server with ID:", socket.id);
  //   });

  //   socket.on("messageFromServer", (message) => {
  //     console.log("Message from server:", message);
  //   });
  //   socket.on("someEvent", (data) => {
  //     console.log("Received data:", data);
  //     // Handle the received data
  //   });
  //   // Don't disconnect the socket here unless explicitly required
  //   return () => {
  //     socket.off("connect");
  //     socket.off("messageFromServer");
  //     socket.off("someEvent");
  //   };
  // }, []);
  // useEffect(() => {
  //   // Listen for the message from the server
  //   socket.on("messageFromServer", (msg) => {
  //     console.log(msg); // Update the state with the received message
  //   });

  //   // Cleanup the socket connection when the component unmounts
  //   return () => {
  //     socket.off("messageFromServer");
  //   };
  // }, []);
  useEffect(() => {
    const customerid = localStorage.getItem("CustomerID");

    if (customerid) {
      const fetchUserData = async () => {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/user/getuser`,
            { customerid },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          // console.log(data);
          setCustomer({ ...customer, data: data });
          setIsAuthenticated(true);
        } catch (error) {
          setError("Failed to fetch user data.");
        }
      };

      fetchUserData();
    } else {
      setError("No customerid found. Please log in.");
    }
  }, [isUpdate]);

  useEffect(() => {
    const customerid = localStorage.getItem("CustomerID");

    const verifyCustomerId = async () => {
      if (customerid) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/user/verifyCustomerId`,
            { customerid }
          );

          // If customer ID exists, user is authenticated
          if (response.data.exists) {
            setIsAuthenticated(true);
          } else {
            // If the customer ID does not exist, log them out and redirect
            setError("Customer ID not found. Please log in.");
          }
        } catch (error) {
          setError("Failed to verify customer ID.");
        }
        setLoading(false); // stop loading after verification
      } else {
        setError("No customer ID found. Please log in.");
        setLoading(false); // stop loading if there's no customer ID
      }
    };

    verifyCustomerId();
  }, []);

  // Show loading state while verifying
  if (loading) {
    return (
      <>
        <div className="Loader">
          <img src={Loader} alt="Loading" />
        </div>
      </>
    );
  }

  // Once loading is complete, render the routes
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Animation />} />
          <Route path="/redirect-loader/:token" element={<RedirectPage />} />

          <Route path="/login" element={<Login />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/forgotpassword" element={<Forgetpassword />} />

          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reports-cards" element={<ReportsDetails />} />
            <Route path="/admin-account" element={<AdminAccount />} />
            <Route path="/live-tracker" element={<LiveTracker />} />
            <Route path="/emp-details" element={<EmployeeDetails />} />
          </Route>

          {/* Redirect to login if no matching route */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
