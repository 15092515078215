import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
  width: 200, // Set the width of the Box
  height: 300, // Set the height of the Box
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const IdProof = ({ open, handleClose, idPic }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img
          src={idPic}
          alt="ID Proof"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Box>
    </Modal>
  );
};

export default IdProof;
