import React, { useState, useEffect } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
// import watermark from "../../../assets/images/watermarkbw.png";
import logo from "../../../assets/images/Okulr_LogoPdf.png";
import { useEmployee } from "../../../context/EmpProvider";
import Loader from "../../../assets/gif/Loader.gif";
import { useCustomer } from "../../../context/CustomerProvider";
const AttendanceTable = () => {
  const {
    attendanceData,
    setAttendanceData,
    attendanceFilter,
    setAttendanceFilter,
  } = useEmployee();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  useEffect(() => {
    // console.log("customer?.data?.businesslogo",customer?.data?.businesslogo)
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const customerId = localStorage.getItem("CustomerID");
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/liveTracker/getAttenDanceReports?customerid=${customerId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAttendanceData(data);
        setAttendanceFilter(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, []);

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "In-Timing Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Out-Timing Snippet" },
  ];

  // Format date to YYYY-MM-DD
  const formatDate = (dateString) => {
    return moment(dateString).format("YYYY-MM-DD");
  };

  // Format time to HH:mm:ss
  const formatTime = (timeString) => {
    return moment(timeString).format("HH:mm:ss");
  };

  if (loading) {
    return (
      <>
        <div className="list_of_dataLoading">
          <img src={Loader} alt="Loading" />
        </div>
      </>
    );
  }

  if (error) {
    return (
      <div className="list_of_dataLoading">
        <h1>No Attendance Records Found</h1>
      </div>
    );
  }

  //   const handleLogDownload = async () => {
  //     try {
  //       const dataForSummeryApi = attendanceData;

  //       const doc3 = new jsPDF({
  //         orientation: "landscape", // Set landscape orientation for more width
  //         unit: "mm",
  //         format: [297, 210], // Custom size, 297mm width and 210mm height (A4 landscape)
  //       });

  //       doc3.addImage(logo, "PNG", 10, 10, 50, 15);
  //       const tableColumn3 = [
  //         "S.No.",
  //         "Date",
  //         "Employee ID",
  //         "Employee Name",
  //         "Shift Timing",
  //         "In-Timing",
  //         "InSnippet",
  //         "Out-Timing",
  //         "Out-Snippet",
  //       ];
  //       const tableRows3 = await Promise.all(
  //         dataForSummeryApi.map(async (member, index) => {
  //           // Check if intimeOutTime exists and has at least one entry

  //           // If intimeOutTime exists, process the data
  //           const imageUrl1 = member.inTimeSnippet
  //             ? `data:image/jpeg;base64,${member.inTimeSnippet}`
  //             : ""; // Default to empty string if no snippet

  //           const imageUrl2 = member.outTimeSnippet
  //             ? `data:image/jpeg;base64,${member.outTimeSnippet}`
  //             : ""; // Default to empty string if no snippet

  //           console.log(member);
  //           return [
  //             index + 1,
  //             formatDate(member.date) || "--",
  //             member.employeeId || "--",
  //             member.employeeName || "--",
  //             member.shiftTiming || "--",
  //             formatTime(member.firstInTime) || "--",
  //             { imageUrl: imageUrl1 },
  //             formatTime(member.lastOutTime) || "--",
  //             { imageUrl: imageUrl2 },
  //           ];
  //         })
  //       );
  //       const pageWidth = doc3.internal.pageSize.width;
  //       const totalTableWidth =
  //       15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25;

  //       const leftMargin = (pageWidth - totalTableWidth) / 2;
  // const title = "In-Out Time Attendance Summary"; // Define the title
  // const textWidth = doc3.getTextWidth(title); // Get the width of the text
  // const textX = (pageWidth - textWidth) / 2; // Calculate the X position for centered text

  // doc3.text(title, textX, 40);
  //       doc3.setTextColor(46, 46, 46);
  //       autoTable(doc3, {
  //         head: [tableColumn3],
  //         body: tableRows3,
  //         startY: 50,
  //         margin: { top: 50, left: leftMargin, right: 10 },
  //         styles: { overflow: "linebreak", cellWidth: "wrap" },
  //         bodyStyles:{ cellPadding: { top: 7, bottom: 7 ,left:2} },
  //         headStyles: { cellPadding: { top: 7, bottom: 7,left:2 } },
  //         columnStyles: {
  //           0: { cellWidth: 15 },
  //           1: { cellWidth: 30 },
  //           2: { cellWidth: 35 },
  //           3: { cellWidth: 35 },
  //           4: { cellWidth: 25 },
  //           5: { cellWidth: 20 },
  //           6: { cellWidth: 30 },
  //           7: { cellWidth: 23 },
  //           8: { cellWidth: 30 },
  //         },
  //         didDrawCell: (data) => {
  //           if (data.column.index === 6 || data.column.index === 8) {
  //             const imageUrl = data.cell.raw;
  //             if (imageUrl) {
  //               // Add the image at the appropriate location
  //               doc3.addImage(
  //                 imageUrl,
  //                 "JPEG",
  //                 data.cell.x + 2,
  //                 data.cell.y + 2,
  //                 15,
  //                 15
  //               );
  //             }
  //           }

  //         },

  //         horizontalPageBreakRepeat: true,
  //       });

  //       doc3.save(`attendance.pdf`);
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };

  // const handleLogDownload = async () => {
  //   try {
  //     const dataForSummeryApi = attendanceData;

  //     const doc3 = new jsPDF({
  //       orientation: "landscape", // Set landscape orientation for more width
  //       unit: "mm",
  //       format: [297, 210], // Custom size, 297mm width and 210mm height (A4 landscape)
  //     });

  //     doc3.addImage(logo, "PNG", 10, 10, 50, 15);

  //     const tableColumn3 = [
  //       "S.No.",
  //       "Date",
  //       "Employee ID",
  //       "Employee Name",
  //       "Shift Timing",
  //       "In-Timing",
  //       "InSnippet",
  //       "Out-Timing",
  //       "Out-Snippet",
  //     ];

  //     // Prepare table rows without image URLs
  //     const tableRows3 = dataForSummeryApi.map((member, index) => {
  //       return [
  //         index + 1,
  //         formatDate(member.date) || "--",
  //         member.employeeId || "--",
  //         member.employeeName || "--",
  //         member.shiftTiming || "--",
  //         formatTime(member.firstInTime) || "--",
  //         "", // Placeholder for image
  //         formatTime(member.lastOutTime) || "--",
  //         "", // Placeholder for image
  //       ];
  //     });

  //     const pageWidth = doc3.internal.pageSize.width;
  //     const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25;

  //     const leftMargin = (pageWidth - totalTableWidth) / 2;
  //     const title = "In-Out Time Attendance Summary"; // Define the title
  //     const textWidth = doc3.getTextWidth(title); // Get the width of the text
  //     const textX = (pageWidth - textWidth) / 2; // Calculate the X position for centered text

  //     doc3.text(title, textX, 40);
  //     doc3.setTextColor(46, 46, 46);

  //     autoTable(doc3, {
  //       head: [tableColumn3],
  //       body: tableRows3,
  //       startY: 50,
  //       margin: { top: 50, left: leftMargin, right: 10 },
  //       styles: { overflow: "linebreak", cellWidth: "wrap" },
  //       bodyStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
  //       headStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
  //       columnStyles: {
  //         0: { cellWidth: 15 },
  //         1: { cellWidth: 30 },
  //         2: { cellWidth: 35 },
  //         3: { cellWidth: 35 },
  //         4: { cellWidth: 25 },
  //         5: { cellWidth: 20 },
  //         6: { cellWidth: 30 },
  //         7: { cellWidth: 23 },
  //         8: { cellWidth: 30 },
  //       },
  //       didDrawCell: (data) => {
  //         if (data.column.index === 6 || data.column.index === 8) {
  //           const member = data.row.raw;
  //           const imageBase64 = member[data.column.index === 6 ? 'inTimeSnippet' : 'outTimeSnippet'];
  //           if (imageBase64) {
  //             doc3.addImage(
  //               `data:image/jpeg;base64,${imageBase64}`,
  //               "JPEG",
  //               data.cell.x + 2,
  //               data.cell.y + 2,
  //               15,
  //               15
  //             );
  //           }
  //         }
  //       },
  //       horizontalPageBreakRepeat: true,
  //     });

  //     doc3.save(`attendance.pdf`);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const handleLogDownload = async () => {
    try {
      const dataForSummeryApi = attendanceData;

      const doc3 = new jsPDF({
        orientation: "landscape", // Set landscape orientation for more width
        unit: "mm",
        format: [297, 210], // Custom size, 297mm width and 210mm height (A4 landscape)
      });

      if (companyLogo) {
        // Load the logo image to calculate its dimensions
        const logoImage = new Image();
        logoImage.src = companyLogo; // Assuming companyLogo is a base64 encoded image

        logoImage.onload = async () => {
          const originalWidth = logoImage.width;
          const originalHeight = logoImage.height;

          // Set maximum dimensions
          const maxWidth = 70; // Adjust maximum width
          const maxHeight = 35; // Adjust maximum height

          let logoWidth = originalWidth;
          let logoHeight = originalHeight;

          // Calculate aspect ratio and adjust width/height to fit within max dimensions
          const aspectRatio = originalWidth / originalHeight;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          // Add the logo to the PDF with the adjusted size
          doc3.addImage(companyLogo, "JPEG", 10, 10, logoWidth, logoHeight);

          const tableColumn3 = [
            "S.No.",
            "Date",
            "Employee ID",
            "Employee Name",
            "Shift Timing",
            "In-Timing",
            "InSnippet",
            "Out-Timing",
            "Out-Snippet",
          ];

          // Prepare table rows with placeholders for images
          const tableRows3 = dataForSummeryApi.map((member, index) => {
            return [
              index + 1,
              formatDate(member.date) || "--",
              member.employeeId || "--",
              member.employeeName || "--",
              member.shiftTiming || "--",
              formatTime(member.firstInTime) || "--",
              "", // Placeholder for image
              formatTime(member.lastOutTime) || "--",
              "", // Placeholder for image
            ];
          });

          const pageWidth = doc3.internal.pageSize.width;
          const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25;

          const leftMargin = (pageWidth - totalTableWidth) / 2;
          const title = "In-Out Time Attendance Summary"; // Define the title
          const textWidth = doc3.getTextWidth(title); // Get the width of the text
          const textX = (pageWidth - textWidth) / 2; // Calculate the X position for centered text

          doc3.text(title, textX, 40);
          doc3.setTextColor(46, 46, 46);

          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 50,
            margin: { top: 50, left: leftMargin, right: 10 },
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
            headStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 30 },
              2: { cellWidth: 35 },
              3: { cellWidth: 35 },
              4: { cellWidth: 25 },
              5: { cellWidth: 20 },
              6: { cellWidth: 30 },
              7: { cellWidth: 23 },
              8: { cellWidth: 30 },
            },
            didDrawCell: (data) => {
              if (
                data.section === "body" &&
                (data.column.index === 6 || data.column.index === 8)
              ) {
                console.log("row check", data.row.index);

                const rowIndex = data.row.index;
                if (rowIndex >= 0 && rowIndex < dataForSummeryApi.length) {
                  const member = dataForSummeryApi[rowIndex];
                  const imageBase64 =
                    data.column.index === 6
                      ? member.inTimeSnippet
                      : member.outTimeSnippet;

                  if (imageBase64) {
                    try {
                      doc3.addImage(
                        `data:image/jpeg;base64,${imageBase64}`,
                        "JPEG",
                        data.cell.x + 2,
                        data.cell.y + 2,
                        15,
                        15
                      );
                    } catch (error) {
                      console.log("Error adding image:", error);
                    }
                  }
                }
              }
            },
            horizontalPageBreakRepeat: true,
          });

          doc3.save(`attendance.pdf`);
        };
        logoImage.onerror = () => {
          console.error(
            "Failed to load the logo image from the base64 string."
          );
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="attendanceTable">
      <div className="list">
        <table>
          <thead>
            <tr className="tableAttendanceHeading">
              {tableHeading.map((h) => (
                <th key={h?.id}>{h?.name}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {attendanceData.length > 0 ? (
              attendanceData.map((data, index) => (
                <tr key={index} className="tableDataName">
                  <td>{index + 1}</td>
                  <td>{formatDate(data.date)}</td>
                  <td>{data.employeeId}</td>
                  <td>{data.employeeName}</td>
                  <td>{data?.shiftTiming?data.shiftTiming:"N/A"}</td>
                  <td>
                    {data.firstInTime ? formatTime(data.firstInTime) : "N/A"}
                  </td>
                  <td>
                    {data.inTimeSnippet ? (
                      <img
                        style={{ height: "42px", width: "40px" }}
                        src={`data:image/png;base64,${data.inTimeSnippet}`}
                        alt="In Time Snippet"
                      />
                    ) : (
                      "No snippet available"
                    )}
                  </td>
                  <td>
                    {data.lastOutTime ? formatTime(data.lastOutTime) : "N/A"}
                  </td>
                  <td>
                    {data.outTimeSnippet ? (
                      <img
                        style={{ height: "42px", width: "40px" }}
                        src={`data:image/png;base64,${data.outTimeSnippet}`}
                        alt="Out Time Snippet"
                      />
                    ) : (
                      "No snippet available"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: "center" }}>
                  <h1> No employee data found</h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="button_download">
        <button onClick={handleLogDownload}>
          <span>Download</span>
        </button>
      </div>
    </div>
  );
};

export default AttendanceTable;
