import React, { useContext, useState } from "react";
import { empContext } from "./myProvider";
import axios from "axios";
export const useEmployee = () => {
  return useContext(empContext);
};
const EmpProvider = ({ children }) => {
  const [employee, setEmployee] = useState([]);
  const [error, setError] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceFilter, setAttendanceFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [completeLog, setCompleteLog] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({
    _id: null,
    customerid: "",
    firstName: "",
    isFaceRegistered: false,
    lastName: "",
    employeeId: "",
    dob: "",
    gender: "",
    emailPersonal: "",
    emailOfficial: "",
    mobileNo: "",
    address: "",
    joiningDate: "",
    department: "",
    designation: "",
    employmentType: "",
    workLocation: "",
    blockOrBuildingNo: "",
    shift: "",
    idProofType: "",
    idProofNo: "",
    idProofPhoto: null,
    isTerminated: false,
    isSuspended: false,
    createdAt: "",
    updatedAt: "",
    profilePhoto: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    city:"",

    __v: null,
  });
  const handleTerminated = async (employeeDetails) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/edit`,
        { isTerminated: true },
        {
          params: { employeeId: employeeDetails?.employeeId },
        }
      );

      if (response.data.success) {
        const empInfo = employee?.map((e, i) => {
          if (e.employeeId === employeeDetails.employeeId) {
            setEmployeeDetails({ ...employeeDetails, isTerminated: true });
            return { ...e, isTerminated: true };
          }
          return e;
        });
        console.log(empInfo);
        setEmployee(empInfo);
      }
      return true;
    } catch (e) {
      // console.log("error during terminated Employee");
      setError("error during terminated Employee");
      return false;
    }
  };

  const handleSuspended = async (employeeDetails, type) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/edit`,
        { isSuspended: type === "Suspend" ? true : false },
        {
          params: { employeeId: employeeDetails?.employeeId },
        }
      );

      if (response.data.success) {
        const empInfo = employee?.map((e, i) => {
          if (e.employeeId === employeeDetails.employeeId) {
            setEmployeeDetails({
              ...employeeDetails,
              isSuspended: type === "Suspend" ? true : false,
            });
            return { ...e, isSuspended: type === "Suspend" ? true : false };
          }
          return e;
        });
        console.log(empInfo);
        setEmployee(empInfo);
      }
      return true;
    } catch (e) {
      // console.log("error during terminated Employee");
      setError("error during terminated Employee");
      return false;
    }
  };
  return (
    <empContext.Provider
      value={{
        employee,
        setError,
        setEmployee,
        handleTerminated,
        handleSuspended,
        employeeDetails,
        setEmployeeDetails,
        isUpdate,
        setIsUpdate,
        attendanceData,
        setAttendanceData,
        attendanceFilter,
        setAttendanceFilter,
        filteredData,
        setFilteredData,
        completeLog,
        setCompleteLog,
      }}
    >
      {children}
    </empContext.Provider>
  );
};

export default EmpProvider;

// "_id": "66d69e5093acce60bf7cc87f",
// "customerid": "abc123",
// "firstName": "Thomas",
// "isFaceRegistered": false,
// "lastName": "Martin",
// "employeeId": "SPAI/03/0014",
// "dob": "1990-04-12T00:00:00.000Z",
// "gender": "Male",
// "emailPersonal": "thomas@gmail.com",
// "emailOfficial": "thomas@safepro.tech",
// "mobileNo": "9876543210",
// "address": "123, fsfsfw, Bangalore, Karnataka- 565784",
// "dateOfJoining": "2022-06-11T00:00:00.000Z",
// "department": "Technical",
// "designation": "Software Developer",
// "employmentType": "Full Time",
// "workLocation": "Rajaji Nagar",
// "blockOrBuildingNo": "26-B",
// "shift": "9:00AM - 06:00PM",
// "idProofType": "Pan Card",
// "idProofNo": "CBR000123",
// "idProofPhoto": "ID_Proof_Photo.pdf",
// "isTerminated": false,
// "isSuspended": false,
// "createdAt": "2024-09-03T05:27:44.628Z",
// "updatedAt": "2024-09-03T05:27:44.628Z",
// "__v": 0
