import React, { useState } from "react";
import Face from "../../../assets/images/face.svg";
import backGroundImage from "../../../assets/images/from_back.svg";
import facesenseHeading from "../../../assets/images/faceHeading.svg";
import EyeOpen from "../../../assets/images/eyeOpen.svg";
import EyeClose from "../../../assets/images/eyeClose.svg";
import backArrow from "../../../assets/images/backArrow.svg";
import Loader from "../../../assets/gif/Loader.gif";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../../../assets/images/Okulr_Logo.png";

import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getEmailFromCookie = () => {
    // Get the encrypted email from the cookie
    const encryptedEmail = Cookies.get("forgotPasswordEmail");

    if (encryptedEmail) {
      // Decrypt the email
      const bytes = CryptoJS.AES.decrypt(encryptedEmail, "your-secret-key");
      const email = bytes.toString(CryptoJS.enc.Utf8);

      return email;
    }

    return null;
  };

  // const { email } = location.state || {};
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showloader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(false);
    setMessage("");
  };

  const handleClick = async () => {
    if (formData.password === formData.confirmpassword) {
      const email = getEmailFromCookie();
      if (!email) {
        return "no email found";
      }
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/changepassword`,
        {
          email: email,
          newpassword: formData.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status_code === 200) {
        setError(false);
        setMessage(response.data.message);
        setShowLoader(false);
        Cookies.remove("forgotPasswordEmail");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setShowLoader(false);
        setError(true);
        setMessage(response.data.message);
      }
    } else {
      setError(true);
      setMessage("Passwords Don't Match");
    }
  };

  return (
    <>
      {showloader && (
        <>
          <div className="Loader">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )}
      <header>
        <div className="Header_Component">
          <img src={Logo} alt="Okulr Techminds" />
        </div>
      </header>
      <div className="Main_Background">
        <div className="Left_Section">
          <div className="Left_Section_Contents">
            <img src={Face} alt="Facesense" />
            <h1>Your Face is Your ID</h1>
            <p>The new era has arrived !</p>
          </div>
        </div>
        <div className="Right_Section">
          <img src={backGroundImage} className="Form_Background" alt="Back" />
          <div className="Right_Section_Contents">
            <img src={facesenseHeading} alt="Facesense" />
            <div className="Input_Box">
              <h2>Change Password</h2>
              <div className="Input_Field">
                <div className="Label">Password</div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                {showPassword ? (
                  <img
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    src={EyeOpen}
                    alt="Eye Button"
                  />
                ) : (
                  <img
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    src={EyeClose}
                    alt="Eye Button"
                  />
                )}
              </div>
              <div className="Input_Field">
                <div className="Label">Confirm Password</div>
                <input
                  type={showPassword1 ? "text" : "password"}
                  name="confirmpassword"
                  value={formData.confirmpassword}
                  onChange={handleInputChange}
                  required
                />
                {showPassword1 ? (
                  <img
                    onClick={() => {
                      setShowPassword1(!showPassword1);
                    }}
                    src={EyeOpen}
                    alt="Eye Button"
                  />
                ) : (
                  <img
                    onClick={() => {
                      setShowPassword1(!showPassword1);
                    }}
                    src={EyeClose}
                    alt="Eye Button"
                  />
                )}
              </div>
            </div>
            <div
              className="Email_Sent"
              style={{ color: error ? "#eb0100" : "#01a601" }}
            >
              {message}
            </div>

            <button type="button" onClick={handleClick}>
              Submit
            </button>
            <img
              className="Back_Button"
              onClick={() => {
                navigate("/forgotpassword");
              }}
              src={backArrow}
              alt="Back Button"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
